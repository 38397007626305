import { createRef, Component } from "react";
import classNames from "classnames";
import { ReactComponent as Upload } from "bootstrap-icons/icons/upload.svg";

const getErrorMessage = (event) => {
  switch (event.target.error.code) {
    case event.target.error.NOT_FOUND_ERR:
      return "File Not Found!";
    case event.target.error.NOT_READABLE_ERR:
      return "File is not readable";
    case event.target.error.ABORT_ERR:
      break;
    default:
      return "An error occurred reading this file.";
  }
};

class FileInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      supportsAdvancedUpload: true,
      isDragOver: false,
      isUploading: false,
      errorMsg: null,
    };
    this.fileInput = createRef();
  }

  handleSelect = (event) => {
    this.handleSubmit(this.fileInput.current.files[0]);
  };

  handleSubmit = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      this.setState({ isUploading: false });
      const data = event.target.result;
      this.props.onRead(file.name, file.size, file.type, data);
    };
    reader.onerror = (event) => {
      this.setState({ isUploading: false });
      this.setState({ errorMsg: getErrorMessage(event) });
      reader.abort();
    };
    reader.readAsText(file, "UTF-8");
  };

  handleDragStart = (event) => {
    event.preventDefault();
    this.setState({ isDragOver: true });
  };

  handleDragEnd = (event) => {
    event.preventDefault();
    this.setState({ isDragOver: false });
  };

  handleDrop = (event) => {
    event.preventDefault();
    this.setState({ isDragOver: false, isUploading: true });
    let droppedFile = event.dataTransfer.files[0];
    this.handleSubmit(droppedFile);
  };

  componentDidMount() {
    const div = document.createElement("div");
    const supportsAdvancedUpload =
      ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
      "FormData" in window &&
      "FileReader" in window;
    this.setState({ supportsAdvancedUpload });
  }

  render() {
    return (
      <form
        className={classNames({
          "file-input": true,
          "has-advanced-upload": this.state.supportsAdvancedUpload,
          "is-dragover": this.state.isDragOver,
          "is-uploading": this.state.isUploading,
        })}
        onDragOver={this.handleDragStart}
        onDragEnter={this.handleDragStart}
        onDragLeave={this.handleDragEnd}
        onDragEnd={this.handleDragEnd}
        onDrop={this.handleDrop}
      >
        <div className="file-input__icon">
          <Upload />
        </div>
        <input
          type="file"
          name="file"
          id="file"
          className="file-input__input"
          ref={this.fileInput}
          onChange={this.handleSelect}
        />
        <label htmlFor="file" className="file-input__label">
          <span>Drag and drop</span>
          <br />
          <small>OR</small>
          <br />
          <span className="file-input__dragndrop">Click to browse files</span>
        </label>
        <div className="file-input__uploading">Uploading&hellip;</div>
        {this.state.errorMsg != null && (
          <div className="file-input__error">{this.state.errorMsg}</div>
        )}
      </form>
    );
  }
}

export default FileInput;
