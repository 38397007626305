import Modal from "react-modal";
import Spinner from "../Spinner/Spinner";

Modal.setAppElement("#root");

const SpinnerModal = ({ header, message }) => {
  return (
    <Modal
      className="modal modal--center"
      overlayClassName="modal-overlay"
      isOpen={true}
      contentLabel="Loading"
    >
      <Spinner message=" " header=" "/>
      <h3 className="mt-5">{header ? header : "Loading..."}</h3>
      <p className="my-4">
        {message ? message : "Please wait whilst we load the page"}
      </p>
    </Modal>
  );
};

export default SpinnerModal;
