import Accordion from "../../components/Accordion/Accordion";
import { ReactComponent as BsChevronRight } from "bootstrap-icons/icons/arrow-right.svg";
import { useReportContext } from "../Context/ReportContext";
import { useHistory } from "react-router-dom";
import { getPayEventStatusPillClasses } from "../../utils/format";
import { getValidationBadgeClass } from "../../utils/displayLabelValues";

const ValidationMessages = ({ report }) => {
  const { setReport } = useReportContext();
  const history = useHistory();

  return report?.validationResponse?.hasErrors ? (
    <Accordion
      expanded={false}
      title={`<span>Show messages</span>`}
      content={
        <div>
          <div className="title-bar">
            {report?.validationResponse?.validations?.length} message
            {report?.validationResponse?.validations?.length > 1 ? "s" : ""}
          </div>
          <div className="message-container">
            {report?.validationResponse?.validations.map(
              (validation, index) => {
                return (
                  <div className="message" id={index}>
                    <div className="message__short-description">
                      <span
                        className={`badge ${getValidationBadgeClass(
                          validation?.severity
                        )}`}
                      >
                        {validation?.severity}
                      </span>{" "}
                      <h4>{validation?.message}</h4>
                    </div>

                    <p className="message__long-description">
                      {validation?.details}
                    </p>
                    <div className="message__extra-details">
                      <p>
                        <span>Message code: </span>
                        <span>{validation?.errorCode}</span>
                      </p>
                      {validation?.path && (
                        <p>
                          <span>Field name: </span>
                          <span>{validation?.path}</span>
                        </p>
                      )}
                    </div>
                    {validation?.contextDetails && (
                      <div className="message__company-member">
                        {validation?.contextDetails?.employeePayrollId !=
                        null ? (
                          <>
                            <div>
                              <span>
                                {validation?.contextDetails?.employeeGivenName}{" "}
                                {validation?.contextDetails?.employeeFamilyName}
                              </span>
                              <span>
                                {" "}
                                (Payroll ID:{" "}
                                {validation?.contextDetails?.employeePayrollId})
                              </span>
                            </div>
                            <div className="submission-id">
                              <span>
                                Submission ID:{" "}
                                {validation?.contextDetails?.submissionId}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <span>
                                {validation?.contextDetails?.companyName}
                              </span>
                              <span>
                                {" "}
                                (
                                {
                                  validation?.contextDetails
                                    ?.companyIdentifierType
                                }
                                :{" "}
                                {validation?.contextDetails?.companyIdentifier})
                              </span>
                            </div>
                            <div className="submission-id">
                              <span>
                                Submission ID:{" "}
                                {validation?.contextDetails?.submissionId}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                );
              }
            )}
            {report?.status === "FAILED" &&
              report?.validationResponse?.hasFileErrors && (
                <div className="resubmit">
                  Please amend the XML and&nbsp;
                  <button
                    className="button button--link button--exact-width"
                    onClick={() => {
                      setReport(null);
                      history.push("/stp/new");
                    }}
                  >
                    relodge
                  </button>
                  &nbsp;
                  <BsChevronRight />
                </div>
              )}
          </div>
        </div>
      }
    />
  ) : null;
};

export default ValidationMessages;
