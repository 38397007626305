import { ReactComponent as ExclamationCircle } from "bootstrap-icons/icons/exclamation-circle.svg";
import { ReactComponent as ExclamationCircleFill } from "bootstrap-icons/icons/exclamation-circle-fill.svg";

const Alert = ({ variation = "", title, children }) => {
  return (
    <div className={`alert ${variation}`}>
      <div className="alert__container">
        <div className="alert__icon">
          {/* <ExclamationCircle /> */}
          <ExclamationCircleFill />
        </div>
        <div className="alert__content">
          <p className="alert__title">{title}</p>
          <div className="alert__message">{children()}</div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
