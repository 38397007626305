import { useEffect, useState } from "react";
import { ReactComponent as ExclamationCircleFill } from "bootstrap-icons/icons/exclamation-circle-fill.svg";
import { ReactComponent as ChevronExpand } from "bootstrap-icons/icons/chevron-expand.svg";
import { ReactComponent as ChevronContract } from "bootstrap-icons/icons/chevron-contract.svg";
import classNames from "classnames";
import ErrorsGroupedByEmployer from "./ErrorsGroupedByEmployer";
import ErrorsGroupedByError from "./ErrosGroupedByError";
import { useReportContext } from "../../Context/ReportContext";
import {
  groupErrorsByEmployer,
  groupErrorsByError,
} from "../../../utils/groupBy";
import PaginationBar from "../../../components/PaginationBar/PaginationBar";
import { DEFAULT_PAGE_SIZE } from "../../../utils/configs";

const ValidationData = ({ dispatch }) => {
  const { report } = useReportContext();

  const errorsGroupedByEmployer = groupErrorsByEmployer(
    report?.validationResponse?.validations
  );
  const errorsGroupedByError = groupErrorsByError(
    report?.validationResponse?.validations
  );
  const [groupByEmployer, setGroupByEmployer] = useState(true);
  const [allExpanded, setAllExpanded] = useState(false);
  const [paginationData, setPaginationData] = useState({
    totalItems: groupByEmployer
      ? Object.keys(errorsGroupedByEmployer).length
      : Object.keys(errorsGroupedByError).length,
    pageNumber: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [
    errorsGroupedByEmployerPage,
    setErrorsGroupedByEmployerPage,
  ] = useState(null);
  const [errorsGroupedByErrorPage, setErrorsGroupedByErrorPage] = useState(
    null
  );

  const setPaginationParams = (pageNumber, pageSize) => {
    setPaginationData({
      ...paginationData,
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
  };

  const setGroupByEmployerValues = (groupByEmployer) => {
    setPaginationData({ ...paginationData, pageNumber: 0 });
    setGroupByEmployer(groupByEmployer);
  };

  useEffect(() => {
    const getItemsForPage = (items) => {
      return Object.keys(items)
        .filter(
          (key) =>
            paginationData.pageNumber * paginationData.pageSize <=
              Object.keys(items).indexOf(key) &&
            Object.keys(items).indexOf(key) <
              (parseInt(paginationData.pageNumber) + 1) *
                paginationData.pageSize
        )
        .map((key) => [items[key]]);
    };

    if (groupByEmployer && errorsGroupedByEmployer != null) {
      setErrorsGroupedByEmployerPage(getItemsForPage(errorsGroupedByEmployer));
      setPaginationData({
        ...paginationData,
        totalItems: Object.keys(errorsGroupedByEmployer).length,
      });
    } else if (!groupByEmployer && errorsGroupedByError != null) {
      setErrorsGroupedByErrorPage(getItemsForPage(errorsGroupedByError));
      setPaginationData({
        ...paginationData,
        totalItems: Object.keys(errorsGroupedByError).length,
      });
    }
  }, [paginationData.pageNumber, paginationData.pageSize, groupByEmployer]);

  return (
    <div>
      <div className="validation-summary">
        <div className="d-flex">
          <ExclamationCircleFill className="validation-summary__icon" />
          <div>
            <div className="validation-summary__title mb-2">
              {report?.validationResponse?.errorCount} error
              {report?.validationResponse?.errorCount > 1 ? "s" : ""} found
            </div>
            <p>
              Please rectify the errors in your XML file and go{" "}
              <button
                type="button"
                className="button button--underline button--inline"
                onClick={() =>
                  dispatch({
                    type: "change_step",
                    payload: {
                      step: 1,
                    },
                  })
                }
              >
                Back
              </button>{" "}
              to re-upload
            </p>
            {(report?.validationResponse?.validations[0]?.errorCode === "ERR999") &&
            (<>
              <hr/>
            <div className="validation-summary__title mb-2">
              XML file uploaded is invalid.
              <p>{report?.validationResponse?.validations[0]?.details}</p>
            </div>
            </>)
            }
          </div>
        </div>
        {/*<button*/}
        {/*  type="button"*/}
        {/*  className="button button--outline button--icon-before ml-4"*/}
        {/*>*/}
        {/*  <FileEarmarkArrowDown className="button--icon-before__icon" />*/}
        {/*  Download errors*/}
        {/*</button>*/}
      </div>
      {!(report?.validationResponse?.validations[0]?.errorCode === "ERR999") && (
          <>
          <hr />

          <div className="error-controls d-flex justify-content-between">
            <button
                type="button"
                className="button button--underline button--icon-before"
                onClick={() => setAllExpanded(!allExpanded)}
            >
              {allExpanded ? (
                  <>
                    <ChevronContract className="button--icon-before__icon" />
                    Collapse all
                  </>
              ) : (
                  <>
                    <ChevronExpand className="button--icon-before__icon" />
                    Expand all
                  </>
              )}
            </button>
            <div>
              <button
                  type="button"
                  className={classNames("button", {
                    "button--underline": !groupByEmployer,
                    "is-bold": groupByEmployer,
                  })}
                  onClick={() => setGroupByEmployerValues(true)}
              >
                Group by name
              </button>
              <span>|</span>
              <button
                  type="button"
                  className={classNames("button", {
                    "button--underline": groupByEmployer,
                    "is-bold": !groupByEmployer,
                  })}
                  onClick={() => setGroupByEmployerValues(false)}
              >
                Group by error
              </button>
            </div>
          </div>

          {groupByEmployer && errorsGroupedByEmployerPage && (
              <div>
                <ErrorsGroupedByEmployer
                    errors={errorsGroupedByEmployerPage}
                    allExpanded={allExpanded}
                />
              </div>
          )}

          {!groupByEmployer && errorsGroupedByErrorPage && (
              <ErrorsGroupedByError
                  errors={errorsGroupedByErrorPage}
                  allExpanded={allExpanded}
              />
          )}
          <PaginationBar
              data={paginationData}
              setPaginationParams={setPaginationParams}
              resultType={"errors"}
          />
          </>
      )}
    </div>

  );
};

export default ValidationData;
