import { useEffect, useState } from "react";
import classNames from "classnames";
import nanoid from "nanoid";
import { ReactComponent as PlusSquare } from "bootstrap-icons/icons/plus-square.svg";
import { ReactComponent as DashSquare } from "bootstrap-icons/icons/dash-square.svg";

const Accordion = ({ expanded = false, title, subTitle, content }) => {
  const [isExpanded, setIsExpanded] = useState(expanded || false);
  const id = nanoid(5);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <dl className="accordion" role="presentation">
      <dt role="heading" aria-level="3">
        <button
          className="button accordion__trigger"
          aria-expanded={isExpanded}
          id={`trigger-${id}`}
          aria-controls={`panel-${id}`}
          onClick={(event) => {
            event.preventDefault();
            setIsExpanded(!isExpanded);
          }}
        >
          <span className="d-flex align-items-center">
            {isExpanded ? (
              <DashSquare className="button--icon-before__icon mr-4 flex-shrink-0" />
            ) : (
              <PlusSquare className="button--icon-before__icon mr-4 flex-shrink-0" />
            )}
            <span className="accordion__title"><div dangerouslySetInnerHTML={{ __html: title }} /></span>
          </span>
          {subTitle && (
            <span className="accordion__subtitle ml-4">{subTitle}</span>
          )}
        </button>
      </dt>
      <dd
        className={classNames("accordion__panel", {
          animateIn: isExpanded,
        })}
        id={`panel-${id}`}
        role="region"
        aria-labelledby={`trigger-${id}`}
        aria-hidden={!isExpanded}
      >
        <div className="accordion__inner">{content}</div>
      </dd>
    </dl>
  );
};

export default Accordion;
