import { createContext, useContext, useState } from "react";

export const ReportContext = createContext();

export const ProvideReport = ({ children }) => {
  const [report, setReport] = useState({
    eventType: "SUBMIT",
  });

  return (
    <ReportContext.Provider value={{ report, setReport }}>
      {children}
    </ReportContext.Provider>
  );
};

export const useReportContext = () => useContext(ReportContext);
