import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

export const groupErrorsByEmployer = (errors) => {
    errors?.forEach(error => {
        error.employeeFullName = getFullName(error);
        error.isCompanyLevel = error.employeeFullName === 0;
    });
    let sortedArray = sortBy(errors, ['contextDetails.companyName', 'employeeFullName'], ['asc', 'asc'])
    return groupByMulti(sortedArray, ['contextDetails.companyName', 'employeeFullName'])
};

export const groupErrorsByError = (errors) => {
    errors?.forEach(error => {
        error.employeeFullName = getFullName(error);
        error.isCompanyLevel = error.employeeFullName === 0;
    });
    let sortedArray = sortBy(errors, ['message', 'employeeFullName'], ['asc', 'asc'])
    return groupByMulti(sortedArray, ['message', 'employeeFullName'])
};

const getFullName = (error) => {
    let fullName = null;
    if (error.contextDetails.employeeGivenName != null) {
        fullName = error.contextDetails.employeeGivenName + " ";
    }
    if (error.contextDetails.employeeFamilyName != null) {
        fullName = fullName + error.contextDetails.employeeFamilyName;
    }
    return fullName == null ? 0 : fullName;
}

const groupByMulti =  (obj, values, context) => {
    if (!values.length)
        return obj;
    var byFirst = groupBy(obj, values[0], context),
        rest = values.slice(1);
    for (var prop in byFirst) {
        byFirst[prop] = groupByMulti(byFirst[prop], rest, context);
    }
    return byFirst;
};