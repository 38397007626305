import React, { useEffect, useState } from "react";
import pako from "pako";
import Spinner from "../../../components/Spinner/Spinner";
import FileInput from "../../../components/FileInput/FileInput";
import HttpClient from "../../../utils/HttpClient";
import NextButton from "../../../components/Buttons/NextButton";
import BackButton from "../../../components/Buttons/BackButton";
import UploadFile from "./UploadFile";
import { Link, useHistory } from "react-router-dom";
import { useReportContext } from "../../Context/ReportContext";
import PrepareReport from "./PrepareReport";
import Alert from "../../../components/Alert/Alert";
import axios from "axios";
import {AWS_ENABLED} from "../../../utils/configs";
import ProcessingModal from "../../../components/Modal/ProcessingModal";

const PrepareStep = ({ dispatch, cancelProcess }) => {
  const { report, setReport } = useReportContext();
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [data, setData] = useState(null);
  const [processId, setProcessId] = useState(null);
  const [existingProcessId, setExistingProcessId] = useState(null);
  const history = useHistory();
  const [isReplace, setIsReplace] = useState(false);
  const [stpMissingDetailsVersion, setStpMissingDetailsVersion] = useState(
    null
  );
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const changeEventType = (eventType) => {
    setExistingProcessId(report?.processId);
    setReport({ eventType: eventType });
  };

  const cancelHandler = async () => {
    setIsReplace(false);
  };

  const proceedHandler = async () => {
    setExistingProcessId(report?.processId);
    setIsReplace(true);
  };

  const retrievePreSignedUrl = async (fileName) => {
    return await HttpClient.get(
        `/stp-details/get/pre-signed-urls`,
        {params: {
                  processId: existingProcessId,
                  fileName: fileName}
               }
        );
  };

  const uploadFileToS3 = async (preSignedUrl, data) => {
    if (AWS_ENABLED) {
      await axios.put(preSignedUrl, data, {
        headers: {
          "Content-Type": fileType,
        },
        transformRequest: HttpClient.defaults.transformRequest.concat(
            (requestData) => {
              return pako.gzip(requestData);
            }
        ),
      });
    }
  };

  const isFileUploaded = () => {
    return report?.fileName != null || fileName != null;
  };

  const triggerUpload = async () => {
    const uploadData = async (data) => {
      setStpMissingDetailsVersion(null);
      try {
        const preSignedUrlResponse = await retrievePreSignedUrl(fileName);

        await uploadFileToS3(preSignedUrlResponse?.data?.preSignedUrl, data);

        const res = await HttpClient.post(
          `/stp-details/upload/xml/${preSignedUrlResponse?.data?.processId}/${report.eventType.toLowerCase()}`,
      {
              "fileName": fileName
            }
        );

        setProcessId(res.data);
      } catch (err) {
        setIsProcessing(false);
        if (err?.response) {
          console.log("err.response - ", err?.response);
          if (err?.response?.data?.errorCode?.startsWith("STPE-002")) {
            setStpMissingDetailsVersion(
              err?.response?.data?.errorCode?.substring(9, 13)
            );
            setData(null);
          } else if (err?.response?.status === 500) {
            setError(err?.response?.data?.message);
            setData(null);
          }
        }
      }
    };

    if (data != null && report?.processId == null) {
      setIsProcessing(true);
      await uploadData(data);
    } else {
      dispatch({ type: "change_step", payload: { step: 2 } });
    }
  };

  const checkRequestProgress = async () => {
    try {
      const res = await HttpClient.get(
        `/stp-details/validate/xml/${processId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setReport(res.data);
      setIsProcessing(false);
      dispatch({ type: "change_step", payload: { step: 2 } });
    } catch (err) {
      if (err?.response) {
        console.log("err.response - ", err?.response);
        if (err?.response?.status === 400) {
          setReport(err?.response?.data);
          setIsProcessing(false);
          dispatch({ type: "change_step", payload: { step: 2 } });
        } else if (
          err?.response?.data?.message?.includes("still in progress")
        ) {
          setIsProcessing(false);
          setIsOpen(true);
        } else {
          setIsProcessing(false);
          console.log("Error - ", err);
          setError(err?.response?.data?.message);
          setData(null);
        }
      }
    }
  };

  useEffect(() => {

    if (processId != null && report?.processId == null) {
      setTimeout(() => {
        checkRequestProgress();
      }, 10000);
    }
  }, [report, processId]);

  useEffect(() => {}, [stpMissingDetailsVersion]);

  return (
    <div className="section">
      <h2>Prepare your report data</h2>
      {/* TODO: Move below to UI/manual creation users */}
      {/*
      {report.dataType !== "xml" && (
        <div className="payment-details mb-8">
          <h3>Payment details</h3>
          <Formik
            initialValues={{
              frequency: "",
              payDate: "",
              totalGross: "",
              totalPaygw: "",
            }}
            onSubmit={async (values) => {
              // TODO: add schema, add submit function
              await new Promise((r) => setTimeout(r, 500));
              alert(JSON.stringify(values, null, 2));
            }}
          >
            <Form>
              <div className="form-group">
                <label htmlFor="frequency">Pay frequency</label>
                <Field type="text" id="frequency" name="frequency" />
                <ErrorMessage name="frequency" />
              </div>
              <div className="form-group">
                <label htmlFor="pay-date">Pay date</label>
                <Field type="text" id="pay-date" name="payDate" />
                <ErrorMessage name="payDate" />
              </div>
              {report.fileType === "submit" && (
                <Fragment>
                  <div className="form-group">
                    <label htmlFor="total-gross">Total gross payments</label>
                    <Field type="text" id="total-gross" name="totalGross" />
                    <ErrorMessage name="totalGross" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="total-paygw">
                      Total PAYG withheld amount
                    </label>
                    <Field type="text" id="total-paygw" name="totalPaygw" />
                    <ErrorMessage name="totalPaygw" />
                  </div>
                </Fragment>
              )}
            </Form>
          </Formik>
        </div>
      )} */}
      {isOpen && (
          <ProcessingModal proceedHandler={() => {
            setIsOpen(false);
            history.push("/");
          }}
          />
      )}
      {isProcessing ? (
        <Spinner
          message="It can take up to several minutes if the file size is large.<br />Please do not refresh this page."
          header="Processing data..."
        />
      ) : (
        <div>
          <PrepareReport eventTypeHandler={changeEventType} />
          <div className="section-container">
            <h3>Upload XML file</h3>
            {isFileUploaded() && (
              <UploadFile
                fileName={report?.fileName ? report?.fileName : fileName}
                fileSize={fileSize}
                handleReset={proceedHandler}
                cancelHandler={cancelHandler}
                isReplace={isReplace}
              />
            )}
            {(!isFileUploaded() || isReplace) && (
              <FileInput
                onRead={(fileName, fileSize, fileType, fileData) => {
                  setFileName(fileName);
                  setFileSize(fileSize);
                  setFileType(fileType);
                  setData(fileData);
                  setIsReplace(false);
                  setReport({ eventType: report?.eventType });
                  setStpMissingDetailsVersion(null);
                  setError(null);
                }}
              />
            )}
            {stpMissingDetailsVersion && !isReplace && (
              <Alert
                variation="alert--failure"
                title={`Unverified ${stpMissingDetailsVersion} details`}
              >
                {() => {
                  return (
                    <div>
                      <p>
                        You have uploaded an {stpMissingDetailsVersion} file.
                        Please verify your {stpMissingDetailsVersion} details in
                        order to proceed.
                      </p>
                      <Link
                        className="button--link"
                        to={`/settings/stp-details/${stpMissingDetailsVersion}`}
                      >
                        Verify your {stpMissingDetailsVersion} details here
                      </Link>
                    </div>
                  );
                }}
              </Alert>
            )}
            {error && !isReplace && (
              <Alert variation="alert--failure" title={`File upload error`}>
                {() => {
                  return (
                    <div>
                      <p>{error}</p>
                    </div>
                  );
                }}
              </Alert>
            )}
          </div>
          <div className="button-group">
            <div>
              <BackButton
                onClick={() => {
                  history.push("/");
                }}
              >
                Back
              </BackButton>
              <NextButton
                onClick={triggerUpload}
                disabled={!isFileUploaded() || isProcessing || error || stpMissingDetailsVersion}
              >
                Next
              </NextButton>
            </div>
            {report?.processId && (
              <div>
                <button
                  type="button"
                  className="button button--underline"
                  onClick={cancelProcess}
                >
                  Cancel report
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PrepareStep;
