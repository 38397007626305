import Accordion from "../../../components/Accordion/Accordion";
import { ReactComponent as ExclamationCircleFill } from "bootstrap-icons/icons/exclamation-circle-fill.svg";

const ErrorsGroupedByError = ({ errors, allExpanded }) => {
  const getErrorCount = (error) => {
    let errorCount = 0;
    Object.keys(error[0]).map((key1) => {
      errorCount = errorCount + error[0][key1].length;
    });
    return errorCount;
  };

  return (
    <div className="mb-8">
      {errors.map((error, index) => {
        return (
          <Accordion
            expanded={!!allExpanded}
            key={index}
            title={`<span class="badge badge--error">${
              error[0][Object.keys(error[0])[0]][0]?.severity
            }</span> <span>${
              error[0][Object.keys(error[0])[0]][0]?.message
            }</span>`}
            subTitle={getErrorCount(error)}
            content={
              <div>
                <div>
                  <div className="accordion__top-lvl-error">
                    {error[0][Object.keys(error[0])[0]][0]?.details == null ? (
                      <p className="mb-2">
                        {error[0][Object.keys(error[0])[0]][0]?.details}
                      </p>
                    ) : (
                      <p className="mb-2">
                        {error[0][Object.keys(error[0])[0]][0]?.message}
                      </p>
                    )}
                    <p className="p--sm mb-0">
                      Message code:{" "}
                      {error[0][Object.keys(error[0])[0]][0]?.errorCode}
                    </p>
                    <p className="p--sm mb-0 field-name">
                      Field name: {error[0][Object.keys(error[0])[0]][0]?.path}
                    </p>
                  </div>
                  <div>
                    {Object.keys(error[0]).map((key1) => {
                      return (
                        <Accordion
                          key={key1}
                          expanded={!!allExpanded}
                          title={
                            error[0][key1][0]?.isCompanyLevel
                              ? `<span>${error[0][key1][0]?.contextDetails?.companyName}</span> <span class="accordion__title--secondary">(${error[0][key1][0]?.contextDetails?.companyIdentifierType}: ${error[0][key1][0]?.contextDetails?.companyIdentifier})</span>`
                              : `<span>${error[0][key1][0]?.contextDetails?.employeeGivenName}  
                                ${error[0][key1][0]?.contextDetails?.employeeFamilyName}</span> <span class="accordion__title--secondary">(Payroll ID: ${error[0][key1][0]?.contextDetails?.employeePayrollId})</span>`
                          }
                          content={
                            <div className="accordion__error__member-details">
                              {error[0][key1][0]?.isCompanyLevel ? (
                                <p>
                                  Submission ID:{" "}
                                  {
                                    error[0][key1][0].contextDetails
                                      ?.submissionId
                                  }
                                </p>
                              ) : (
                                <>
                                  <p>
                                    Employer:{" "}
                                    {
                                      error[0][key1][0].contextDetails
                                        ?.companyName
                                    }
                                  </p>
                                  <p>
                                    {
                                      error[0][key1][0]?.contextDetails
                                        ?.companyIdentifierType
                                    }
                                    :{" "}
                                    {
                                      error[0][key1][0]?.contextDetails
                                        ?.companyIdentifier
                                    }
                                  </p>
                                  <p>
                                    Submission ID:{" "}
                                    {
                                      error[0][key1][0].contextDetails
                                        ?.submissionId
                                    }
                                  </p>
                                </>
                              )}
                            </div>
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            }
          />
        );
      })}
    </div>
  );
};

export default ErrorsGroupedByError;
