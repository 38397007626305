import BackButton from "../../../components/Buttons/BackButton";
import NextButton from "../../../components/Buttons/NextButton";
import { useHistory } from "react-router-dom";
import { useReportContext } from "../../Context/ReportContext";
import ValidationData from "./ValidationData";
import HttpClient from "../../../utils/HttpClient";
import { useEffect } from "react";
import ReportSummary from "../../../components/ReportSummary/ReportSummary";

const ReviewStep = ({ dispatch, cancelProcess }) => {
  const { report } = useReportContext();
  const history = useHistory();

  useEffect(() => {
    const updateStep = async () => {
      try {
        const res = await HttpClient.put(
          `/stp-details/${report?.processId}/step/2`
        );
      } catch (err) {
        console.log("Error - ", err);
      }
    };

    if (report?.processId) {
      updateStep();
    }
  }, [report?.processId]);

  return (
    <div className="section">
      <h2>Review your report data</h2>
      <div>
        {report?.status !== "INVALID_REQUEST" ? (
          <div>
            {report.payevntSummary && (
              <div>
                {report?.batch ? (
                  <h3>
                    {report?.eventType === "SUBMIT" ? "Pay" : "Update"} event
                  </h3>
                ) : (
                  <h3>
                    {report?.eventType === "SUBMIT" ? "Pay" : "Update"} event
                    {report?.payevntSummary?.payDate !== "-" ? ` | ${report.payevntSummary.payDate}` : ""}
                  </h3>
                )}
                <ReportSummary report={report} />
              </div>
            )}
          </div>
        ) : (
          <ValidationData dispatch={dispatch} />
        )}

        <div className="button-group">
          <div>
            <BackButton
              onClick={() =>
                dispatch({
                  type: "change_step",
                  payload: {
                    step: 1,
                  },
                })
              }
            >
              Back
            </BackButton>
            <NextButton
              onClick={() =>
                dispatch({
                  type: "change_step",
                  payload: {
                    step: 3,
                  },
                })
              }
              disabled={report?.status === "INVALID_REQUEST"}
            >
              Next
            </NextButton>
            <button
              type="button"
              className="button button--underline"
              onClick={() => {
                history.push("/stp");
              }}
            >
              Save and quit
            </button>
          </div>
          <div>
            <button
              type="button"
              className="button button--underline"
              onClick={cancelProcess}
            >
              Cancel report
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewStep;
